body {
  font-family: effra, sans-serif;
}

.app-header {
  position: sticky;
  top: 0;
  height: 99px;
  padding: 0 20px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  transition: all .4s ease;
}

.app-logo img {
  width: 150px;
  transition: all .4s ease;
}

nav a {
  position: relative;
  margin: 0 25px;
  padding-bottom: 33px;
  color: #fff;
  font-size: 20px;
  text-decoration: none;
  border-bottom: 0px solid #9768d1;
  transition: all .2s ease;
}

.app-header.scrolled {
  height: 70px;
  background-color: #fff;
}

.app-header.scrolled .app-logo img {
  width: 100px;
}

.app-header.scrolled nav a {
  color: #1b1b1c;
  padding-bottom: 18px;
}

nav a:hover, nav a.active {
  transition: all .4s ease;
  border-bottom: 6px solid #9768d1;
}

.n-button {
  padding: 15px 30px;
  color: #fff;
  background-color: #f85c5d;
  border-color: #f85c5d;
  border-radius: 0;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .4s ease;
  cursor: pointer;
}

.n-button:hover {
  background-color: #e8494a;
  border-color: #e8494a;
  box-shadow: 0 12px 24px rgba(0, 0, 0, .2);
}

.home-banner {
  position: relative;
  margin-top: -100px;
  width: 100%;
  height: calc(100vh - 100px);
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.about-us-banner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(./assets//image/aboutus.jpeg);
}

.how-it-works-banner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(./assets//image/how-it-works.jpeg);
}

.testimonials-banner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(./assets/image/testimonials.jpeg);
}

.contactus-banner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(./assets//image/contactus.jpeg);
}

.home-banner-text {
  position: absolute;
  bottom: 0;
  padding: 150px 70px 0 70px;
  width: 100%;
  height: 300px;
  font-size: 60px;
  line-height: 50px;
  font-weight: 300;
  background: linear-gradient(to bottom, transparent, white);
  z-index: 9;
}

.home-banner-text span {
  font-size: 30px;
  line-height: 30px;
}

.container {
  margin: 150px auto;
}

.section-title {
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 300;
}

.section-value {
  font-size: 22px;
  font-weight: 300;
  margin: auto;
  color: rgb(77, 75, 80);
}

.statics {
  margin: 60px 0;
  padding: 20px 0;
  text-align: center;
  border-right: 1px solid #d5d5d5;
}

.row .statics:last-child {
  border: 0;
}

.statics .title {
  color: #1b1b1c;
  font-size: 60px;
  font-weight: bold;
}

.statics .separator {
  margin: 15px auto 20px auto;
  width: 50px;
  height: 1px;
  background: #1b1b1c;
}

.statics .value {
  color: #9768d1;
  font-size: 22px;
}

.bg-gradient {
  background: linear-gradient(180deg, #fff, #fff, #ff914d) !important;
  /* background: linear-gradient(0deg, #9768d1 -15.42%, rgba(151, 104, 209, .991) -9.1%, rgba(151, 104, 209, .964) -2.77%, rgba(151, 104, 209, .918) 3.56%, rgba(151, 104, 209, .853) 9.88%, rgba(151, 104, 209, .768) 16.21%, rgba(151, 104, 209, .668) 22.54%, rgba(151, 104, 209, .557) 28.86%, rgba(151, 104, 209, .443) 35.19%, rgba(151, 104, 209, .332) 41.52%, rgba(151, 104, 209, .232) 47.84%, rgba(151, 104, 209, .147) 54.17%, rgba(151, 104, 209, .082) 60.5%, rgba(151, 104, 209, .036) 66.82%, rgba(151, 104, 209, .009) 73.15%, rgba(151, 104, 209, 0) 79.48%) !important; */
}

.key-points {
  padding: 40px 40px 40px 0px;
}

.key-points .title {
  font-size: 26px;
}

.key-points .value {
  padding: 25px 0 120px 0;
  max-width: 345px;
  font-size: 18px;
  font-weight: 300;
}

.bg-image {
  padding: 50px 0;
  background-image: url(./assets/image/invest-bg.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.bg-image .container {
  padding-right: 300px;
}

.bg-logo img {
  width: 300px;
  /* animation: scaleUp 3s infinite; */
}

@keyframes scaleUp {
  0% {
    transform: scale(0.5);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.5);
  }
}

.app-footer {
  padding: 40px 0 70px 0;
  background-color: #eeeeee;
}

.copyright {
  color: #4d4b50;
  font-size: 12px;
  text-align: center;
}

.app-footer nav a {
  color: #4d4b50;
}

.app-footer .app-nav-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.app-footer-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-footer-logo img {
  width: 220px;
}

.page-title {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 160px;
  text-align: center;
  z-index: 9;
  font-size: 80px;
  font-weight: 300;
  background: rgb(0 0 0 / 20%);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-points {
  padding: 25px 0px;
  border-bottom: 1px dashed #f85c5c;
}

.border-points .title {
  color: #f85c5c;
  font-size: 30px;
  font-weight: 500;
}

.border-points .value {
  font-size: 20px;
  font-weight: 300;
}

.our-vision {
  padding-left: 100px;
  display: flex;
  align-items: center;
}

.p-large {
  padding: 80px 0;
}

.steps-container {
  margin-bottom: 50px;
  position: relative;
}

.steps-container:before {
  position: absolute;
  width: 2px;
  height: 97%;
  content: "";
  border-right: 1px dashed #8400cf;
  top: 65px;
  left: 30px;
}

.container .steps-container:last-child::before {
  content: unset;
}

.steps {
  padding: 10px 40px 10px 10px;
  width: max-content;
  color: #fff;
  background-color: #8400cf;
  border-radius: 100px;
  font-size: 30px;
  display: flex;
}

.steps-count {
  margin-right: 20px;
  width: 45px;
  height: 45px;
  color: #8400cf;
  background: #fff;
  border: 1px solid;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.steps-title {
  display: flex;
  align-items: center;
}

.steps-section {
  padding: 25px 75px;
}

.steps-section-title {
  font-size: 35px;
  font-weight: 300;
}

.step-section-value {
  font-size: 22px;
  font-weight: 300;
  margin: auto;
  color: rgb(77, 75, 80);
}

.conneect-now {
  text-align: center;
  margin: 40px;
}

.conatctus-box {
  margin-top: 40px;
}

.contctus-form {
  margin: 0 0 0 80px;
  padding: 40px;
}

.form-label {
  font-size: 20px;
  font-weight: 300;
}

.form-control {
  padding: 10px 20px;
  border-radius: 0;
}

.testimonial-card {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  margin: 20px;
  text-align: center;
  font-weight: 300;
}

.testimonial-title {
  color: #f85c5c;
  margin-bottom: 15px;
}

.testimonial-text {
  margin: 40px 20px;
  color: #555;
  font-style: italic;
}

.testimonial-author {
  margin-top: 15px;
  font-weight: 300;
  color: #333;
}

.full-page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(000, 000, 000, 0.8);
  z-index: 1000;
}

.modal-title {
  font-size: 40px;
  font-weight: 700;
}

.modal-title .bi {
  font-size: 100px;
}

.highcharts-credits {
  display: none;
}

.hamburger {
  background: none;
  border: none;
  padding: 18px;
}

.hamburger .line-1, .hamburger .line-2, .hamburger .line-3 {
  background: #f85c5d;
  border-radius: 2px;
  height: 4px;
  margin: 8px 0;
  width: 35px;
}

.hamburger .line-2 {
  width: 40px;
}

.hamburger .line-3 {
  width: 30px;
}

@media only screen and (max-width: 768px) {
  .app-header {
    padding-left: 0;
    padding-right: 0;
  }

  .offcanvas-header {
    justify-content: center;
  }

  .offcanvas-body .n-button {
    margin-left: 20px;
  }

  .offcanvas-body nav {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }

  .offcanvas-body nav a {
    margin: 15px;
    padding: 8px;
    color: #333;
    border: 0;
  }

  .offcanvas-body nav a.active:after {
    background: #f85c5c;
    bottom: 0;
    content: "";
    height: 2px;
    left: 8px;
    position: absolute;
    transition: .3s;
    width: 20px;
  }

  .home-banner video {
    height: 100%;
    object-fit: cover;
  }

  .home-banner-text {
    height: max-content;
    padding: 200px 20px 20px 20px;
    font-size: 35px;
    line-height: 35px;
  }

  .home-banner-text span {
    font-size: 15px;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .statics {
    margin: 0;
  }

  .section-title {
    font-size: 30px;
    text-align: center;
  }

  .section-value {
    font-size: 18px;
    text-align: center;
  }

  .key-points {
    padding: 0;
  }

  .key-points .title {
    font-size: 22px;
    text-align: center;
  }

  .key-points .value {
    padding: 15px 0 60px 0;
    max-width: unset;
    font-size: 16px;
    text-align: center;
  }

  .bg-image {
    background-size: contain;
    background-position: top;
  }

  .bg-image .container {
    margin-left: -15px;
    margin-right: -15px;
    padding-right: 0;
  }

  .focus-area {
    margin: 0;
  }

  .app-footer nav {
    display: none;
  }

  .page-title {
    font-size: 50px;
  }

  .our-team img {
    margin-top: 60px;
  }

  .our-vision {
    padding: 0;
  }

  .our-vision .section-title {
    margin-top: 40px;
  }

  .steps {
    position: relative;
    width: unset;
    font-size: 25px;
    line-height: 28px;
  }

  .steps-count {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .steps-title {
    padding-left: 55px;
  }

  .steps-container:before {
    width: 0;
    border: 0;
  }

  .steps-section {
    padding: 0;
  }

  .steps-section-title {
    margin-top: 50px;
  }

  .testimonial {
    margin: unset;
  }

  .testimonial-card {
    width: unset;
    margin: 0;
  }

  .contctus-form {
    margin: 50px 0 0 0;
  }
}